import { Flex, Text } from "@mantine/core";
import React from "react";
/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react/prop-types */
import { ICellTextProps } from "ka-table/props";
import { PencilIcon, PencilSquareIcon } from "@heroicons/react/20/solid";
import { useNavigate } from "react-router-dom";
import { useAuthInfo } from "@propelauth/react";
import useWorkspaceStore from "../../store/workspace/workspaceStore";

/**
 * Custom cell component.
 */
const SignalNameCell: React.FC<ICellTextProps> = React.memo(
  ({
    column, dispatch, rowKeyValue, ...props
  }) => {
    const value = props.rowData[`${column.key}`];
    const navigate = useNavigate();
    const { workspace } = useWorkspaceStore();
    const { accessHelper } = useAuthInfo();
    return (
      <Flex
        gap={20}
        align="center"
        className="group w-fit duration-100 ease-linear hover:bg-gray-200 -m-2 p-2 rounded-md"
      >
        <Text c="text.9" className="flex-1" weight={700}>{value}</Text>
        {/* {accessHelper?.hasPermission(workspace?.id, "can_update_automation")
        && ( */}
        <PencilSquareIcon
          onClick={() => {
            navigate(`/home/${workspace?.unique_identifier}/signals/${props.rowData.id}/edit`);
          }}
          className="h-[17px] w-[17px] hidden shrink-0 cursor-pointer group-hover:block duration-100 ease-linear hover:text-gray-500 text-gray-400"
        />
        {/* )} */}
      </Flex>
    );
  },
);
export default SignalNameCell;
